
.course-content {
    padding: 20px;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
